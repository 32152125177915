import React, { useEffect, useRef } from "react";
import DiscoverMoreChip from "@components/pages/feedPage/DiscoverMoreChip";
import Link from "next/link";

type DiscoverMoreTagsProps = {
  discoverMoreTags: any;
  title?: string;
  observer?: any;
};

const DiscoverMoreWidget = ({
  title = "",
  discoverMoreTags,
  observer = null
}: DiscoverMoreTagsProps) => {
  const discoverMoreWidgetRef = useRef(null);
  useEffect(() => {
    if (observer && discoverMoreWidgetRef && discoverMoreWidgetRef.current) {
      observer.observe(discoverMoreWidgetRef.current);
      return () => {
        // observer.destroy();
      };
    }
  }, [observer]);
  return (
    <div
      className="flex h-full grow flex-col pt-5 pb-[1.375rem]"
      id="discoverMoreWidget"
      ref={discoverMoreWidgetRef}
    >
      <h3 className="mb-1 text-title font-bold leading-title text-N900 dark:text-DN900">
        {title || "Discover more"}
      </h3>

      <ul className="flex flex-wrap">
        {discoverMoreTags.length &&
          discoverMoreTags.map((item, index) => (
            <li className="pt-2 pr-2" key={index}>
              <span itemScope itemType="https://schema.org/SiteNavigationElement">
                <Link href={`/${item[0]}`} as={`/${item[0]}`} passHref prefetch={false}>
                  <a itemProp="url" title={item[1]}>
                    <DiscoverMoreChip
                      chipLabel={item[1]}
                      isChipSelected={false}
                      showImg={false}
                    />
                  </a>
                </Link>
              </span>
            </li>
          ))}
      </ul>
    </div>
  );
};

export default DiscoverMoreWidget;
