import { trackCHEvents } from "@analytics/utils/trackEvents";
import { compact } from "@utils/fn-utils";
import { getPageName } from "@utils/getPageName";

export const trackCTAs = ({ element_name, page_section, articleId = undefined }) => {
  let page = getPageName();

  const data = articleId ? { article_id: articleId } : undefined;

  const payload = compact({
    element_name,
    element_type: "icon",
    action_type: "click",
    page_section: page_section,
    screenName: articleId ? "news_details" : page,
    ...data
  });

  if (
    page === "search" ||
    page === "other_trending_reads" ||
    page === "saved_for_later" ||
    page === "explore"
  ) {
    const urlSearchParams = new URLSearchParams(window?.location?.search);
    const params = Object.fromEntries(urlSearchParams.entries());

    if (params?.value) {
      page = `${page}_listing`;
    }
  }
  trackCHEvents(`${articleId ? `news_details` : page}_page_${element_name}_click`, payload);
};
